<template>
    <div class="html-default">
        <label class="form-label" style="font-size: 10px;">{{DatetimeNow}}</label>
        <label class="form-label" style="float: right; font-size: 10px;">{{TransactionNumber}}</label>
        <br>
        <div class="row px-3">
            <div class="col-8">
                <h2><b>PT. BOJONG WESTPLAS</b></h2>
                <label><b>{{RegionName}}</b></label>
            </div>
            <div class="col-4">
                <table width="100%">
                    <tr>
                        <td><label class="form-label">Nomor</label></td>
                        <td><label class="form-label">:</label></td>
                        <td><label class="form-label">{{TransactionNumber}}</label></td>
                    </tr>
                    <tr>
                        <td><label class="form-label">Tanggal</label></td>  
                        <td><label class="form-label">:</label></td>
                        <td><label class="form-label">{{TransactionDate}}</label></td>
                    </tr>
                </table>
            </div>
        </div>
        
        <div style="text-align: center;">
            <h4><b>BUKTI KAS KELUAR</b></h4>
            <label><b>COA KAS : {{COACode}} - {{COAName}}</b></label>
        </div>

        <div class="row px-3">
            <div class="col-12">
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Bayar Ke</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{ContactName}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Uraian</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{Notes}}</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-2">
            <table border="1" class="mytable mytable-head">
                <thead>
                    <tr>
                        <td class="px-1" width="5%" style="text-align: center;"><label class="form-label">NO</label></td>
                        <td class="px-1" width="20%" style="text-align: center;"><label class="form-label">AKUN</label></td>
                        <td class="px-1" width="55%" style="text-align: center;"><label class="form-label">NAMA</label></td>
                        <td class="px-1" width="20%" style="text-align: center;"><label class="form-label">JUMLAH</label></td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in Items" :key="index">
                        <td class="px-1" style="text-align: center;"><label>{{item['seq']}}</label></td>
                        <td class="px-1" style="text-align: center;"><label>{{item['chart_of_account_id']}}</label></td>
                        <!-- <td class="px-1"><label>{{item['chart_of_account_name']}}</label></td> -->
                        <td class="px-1"><label>{{item['notes']}}</label></td>
                        <td class="px-1"><label style="float: right;">{{item['amount']}}</label></td>
                    </tr>
                </tbody>
            </table>
            <table border="1" class="mytable mytable-body">
                <tbody>
                    <tr>
                        <td class="px-2" width="80%" style="text-align: center;"><label><b>TOTAL</b></label></td>
                        <td class="px-2" width="20%"><label style="float: right;">{{Total}}</label></td>
                    </tr>
                </tbody>
            </table>
            <table border="1" class="mytable mytable-footer">
                <tbody>
                    <tr>
                        <td class="px-2" width="100%"><label>Terbilang: {{TotalString}}</label></td>
                    </tr>
                </tbody>
            </table>
            <br>
            <table border="1" class="mytable">
                <thead>
                    <tr>
                        <td class="px-1" width="25%" style="text-align: center;"><label class="form-label">Direktur</label></td>
                        <td class="px-1" width="25%" style="text-align: center;"><label class="form-label">Akunting</label></td>
                        <td class="px-1" width="25%" style="text-align: center;"><label class="form-label">Kasir</label></td>
                        <td class="px-1" width="25%" style="text-align: center;"><label class="form-label">Penerima</label></td>
                    </tr>
                </thead>
                <tbody>
                    <tr style="height:100px">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style="text-align: center;"><br><br><br><label>{{ContactName}}</label></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import moment from 'moment';
import cashOutServices from '../../Areas/Finance/CashOut/Script/CashOutServices.js';
import angkaTerbilang from '@develoka/angka-terbilang-js';

export default {
    name: 'CashOutHtml',
    data(){
        return{
            Id: this.$route.params.id,
            DatetimeNow: '',
            TransactionNumber: '',
            RegionName: '',
            TransactionDate: '',
            COACode: '',
            COAName: '',
            ContactName: '',
            Description: '',
            Notes: '',
            Items: [],
            Total: '',
            TotalString: '',
        }
    },
    async mounted() {
        var data = await cashOutServices.getDetailCashOut(this.Id);
        document.title = data.transaction_number;
            
        this.DatetimeNow = moment(new Date()).format("YYYY-MM-DD hh:mm A");
        this.TransactionNumber = data.transaction_number;
        this.RegionName = data.region_name;
        this.TransactionDate = moment(data.transaction_date).format("DD MMMM YYYY");
        this.COACode = data.chart_of_account_id;
        this.COAName = data.chart_of_account_name;
        this.ContactName = data.contact_name;
        this.Description = data.desc;
        this.Notes = data.notes;

        //table
        var itemData = cashOutServices.itemPDF(data.cash_mutation_detail);
        this.Items = itemData;

        var total = cashOutServices.getAllTotal(data.cash_mutation_detail);
        this.Total = kendo.toString(total, "n2");
        this.TotalString = this.$globalfunc.titleCase(angkaTerbilang(total));
    }
}
</script>

<style scoped>
.col-sm-dot, .col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-dot {
        width: 1%;
    }
    .col-sm-content {
        width: 85%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}

.mytable {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
.mytable-head {
    border: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-head td {
    border: 1px solid black;
}
.mytable-body {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-body td {
    border: 1px solid black;
    border-top: 0;
}
.mytable-footer {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
.mytable-footer td {
    border: 1px solid black;
    border-top: 0;
}
</style>