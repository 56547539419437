<template>
    <div class="html-default">
        <label class="form-label" style="font-size: 10px;">{{datetimeNow}}</label>
        <label class="form-label" style="float: right; font-size: 10px;">{{doNumber}}</label>
        <br>
        <div class="row p-2">
            <div class="col-3 bg">
                <img class="item" :src="require('../../../public/img/avatars/Logo.jpg')" width="100%"/>
            </div>
            <div class="col-9 p-0">
                <br>
                <h3><b>PT. BOJONG WESTPLAS</b></h3>
                <label><b>Modern Pipe Manufacturer</b></label>
            </div>
        </div>

        <div style="text-align: center;">
            <h5 style="font-size: larger;">Surat Jalan</h5>
            <label>{{doNumber}}</label>
        </div>

        <div class="row p-2">
            <div class="col-6 px-3">
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Kantor</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{ CompanyKantor }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Telepon</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{ CompanyPhoneNumber }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Fax</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{ CompanyFax }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Pabrik</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{ CompanyPabrik }} <br> {{CompanyProvinsi}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Nomor PO</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{poNumber}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Nomor SP</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{spNumber}}</label>
                    </div>
                </div>
            </div>
            <div class="col-6 px-3">
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Kepada</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                    <label class="form-label"><span>{{customerName}} <br> {{customerAddress}} <br> {{customerCity}} - {{customerProvince}} <br> Indonesia</span></label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Alamat Kirim</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label"><span>{{addressSend}}</span></label>
                    </div>
                </div>
            </div>
        </div>
        <br>

        <div class="p-2">
            <label class="form-label"><span>Dengan Hormat, <br> Bersama kendaraan No. ................... kami kirimkan barang pesanan Saudara sebagai berikut:</span></label>
            <table border="1" class="mytable mytable-head">
                <thead>
                    <tr>
                        <td class="px-3" width="10%" style="text-align: center;"><label>Jumlah</label></td>
                        <td class="px-3" width="10%" style="text-align: center;"><label>Satuan</label></td>
                        <td class="px-3" width="40%" style="text-align: center;"><label>Nama Barang</label></td>
                        <td class="px-3" width="20%" style="text-align: center;"><label>Keterangan</label></td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                        <td class="px-2"><label style="float: right;">{{item['qty_string']}}</label></td>
                        <td class="px-2"><label>{{item['uom']}}</label></td>
                        <td class="px-2"><label>{{item['name']}}</label></td>
                        <td class="px-2"><label>{{item['note']}}</label></td>
                    </tr>
                </tbody>
            </table>
            <br>

            <div class="row">
                <div class="col-3">
                    <label class="form-label">Catatan</label>
                </div>
                <div class="col-sm-dot">
                    <label class="form-label">:</label>
                </div>
                <div class="col-sm-content">
                    <label class="form-label">{{note}}</label>
                </div>
            </div>
            <br>

            <div class="row">
                <div class="col-9">
                </div>
                <div class="col-3">
                    <label class="form-label"><span>Tangerang, {{doDate}}<br>Hormat Kami,</span></label>
                </div>
            </div>
            <div class="row">
                <div class="col-9">
                    <label class="form-label"><span>Disetujui Oleh Pembeli,</span></label>
                </div>
                <div class="col-3">
                    <label class="form-label"><span>PT Bojong Westplas</span></label>
                </div>
            </div>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <div class="row">
                <div class="col-9">
                    <label class="form-label">Nama Jelas dan Cap Perusahaan</label>
                </div>
                <div class="col-3">
                    <label class="form-label">(Kepala Gudang)</label>
                </div>
            </div>
            <label class="form-label">Print by {{name}} on {{datetimeNow}}</label>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import deliveryOrderServices from '../../Areas/Transaction/DeliveryOrder/Script/DeliveryOrderServices';
import { apiBaseUrlDownload } from '../../infrastructure/constant/connection';

export default {
    name: 'DeliveryOrderHtml',
    data(){
        return{
            Id: this.$route.params.id,
            datetimeNow: '',
            doNumber: '',
            doDate: '',
            items: [],
            customerName: '',
            customerAddress: '',
            customerCity: '',
            customerProvince: '',
            poNumber: '',
            spNumber: '',
            addressSend: '',
            note: '',
            deliveryNote: '',
            attachement: '',
            name: this.$cookies.get('Name'),
            SrcImg: '',
            pdfOption: {
                margin: [5,5],
                html2canvas: {
                    scale: 4,
                    useCORS: true
                },
                jsPDF: {
                    unit: 'mm',
                    format: 'a4',
                    orientation: 'portrait'
                },
                pagebreak: {
                    avoid: ['tr', 'css', 'legacy']
                },
            },
            
            //Company Detail
            CompanyPhoneNumber : '',
            CompanyFax : '',
            CompanyKantor : '',
            CompanyNPWP : '',
            CompanyPabrik : '',
            CompanyProvinsi : '',
        }
    },
    async mounted () {
        //Company Detail
        var companyData = await this.$globalfunc.getCompanyInfo();
        this.CompanyPhoneNumber = companyData.Telepon;
        this.CompanyFax = companyData.Fax;
        this.CompanyKantor = companyData.Kantor;
        this.CompanyNPWP = companyData.NPWP;
        this.CompanyPabrik = companyData.Pabrik;
        this.CompanyProvinsi = companyData.Provinsi;

        var data = await deliveryOrderServices.getDeliveryOrderDetail(this.Id);
        document.title = data.delivery_order_number;
            
        this.datetimeNow = moment(new Date()).format("YYYY-MM-DD hh:mm A");
        this.doNumber = data.delivery_order_number;
        this.customerName = data.customer_name;
        this.customerAddress = data.purchase_order.customer.metadata.profile.address;
        this.customerCity = data.purchase_order.customer.metadata.profile.city;
        this.customerProvince = data.purchase_order.customer.metadata.profile.province;

        this.poNumber = data.purchase_order_number;
        this.spNumber = data.sp_number;
        this.addressSend = data.purchase_order.address;

        //table
        var itemData = deliveryOrderServices.itemDOPDF(data.delivery_order_detail);
        this.items = itemData;

        this.note = data.notes;
        this.deliveryNote = data.additional_notes;
        this.doDate = moment(data.delivery_order_date).format("DD MMM YYYY");

        var customerData = await deliveryOrderServices.getContactDetailQuery({type : "Distributor", id : data.purchase_order.customer_id});
        this.attachement = customerData.attachment1;

        if(customerData.attachment1 != null || customerData.attachment1 != ""){
            var fileUrl = apiBaseUrlDownload + customerData.attachment1;
            this.SrcImg = fileUrl;
        }
    }
}
</script>

<style scoped>
.col-sm-dot, .col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 30%;
    }
    .col-sm-dot {
        width: 5%;
    }
    .col-sm-content {
        width: 60%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
.mytable {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
.mytable-head {
    border: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-head td {
    border: 1px solid black;
}
.mytable-body {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-body td {
    border: 1px solid black;
    border-top: 0;
}
.mytable-footer {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
.mytable-footer td {
    border: 1px solid black;
    border-top: 0;
}
.bg {
    background-color: transparent;
}
.item {
    mix-blend-mode: multiply;
}
</style>