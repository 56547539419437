<template>
    <div class="html-default">
        <table border="1" class="mytable mytable-head">
            <thead>
                <tr>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Tanggal</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>No. Bukti</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Kontak</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Kode Akun</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Nama Akun</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Deskripsi</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Debit</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Kredit</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Uraian</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Divisi</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Budgeting</label></td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in items" :key="index">
                    <td class="px-2"><label>{{ item['transaction_date'] }}</label></td>
                    <td class="px-2"><label>{{ item['transaction_number'] }}</label></td>
                    <td class="px-2"><label>{{ item['contact_name'] }}</label></td>
                    <td class="px-2"><label>{{ item['chart_of_account_id'] }}</label></td>
                    <td class="px-2"><label>{{ item['chart_of_account_name'] }}</label></td>
                    <td class="px-2"><label>{{ item['desc'] }}</label></td>
                    <td class="px-2"><label>{{ item['debit'] }}</label></td>
                    <td class="px-2"><label>{{ item['credit'] }}</label></td>
                    <td class="px-2"><label>{{ item['notes'] }}</label></td>
                    <td class="px-2"><label>{{ item['division'] }}</label></td>
                    <td class="px-2"><label>{{ item['budgeting'] }}</label></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import GeneralLedgerService from '../../Areas/Accounting/GeneralLedger/Script/GeneralLedgerService';

export default {
    name: 'GeneralLedgerHTML',
    data(){
        return{
            StartDate: this.$route.params.startdate,
            EndDate: this.$route.params.enddate,
            items: [],
        }
    },
    async mounted () {
        const variables = {
            startDate: this.StartDate,
            endDate: this.EndDate
        }
        var data = await GeneralLedgerService.getAllLedgerData(variables);
        var itemData = GeneralLedgerService.itemPDF(data)
        this.items = itemData;
    },
    methods: {
    }
}
</script>

<style scoped>
.col-sm-dot, .col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 30%;
    }
    .col-sm-dot {
        width: 5%;
    }
    .col-sm-content {
        width: 60%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}

.mytable {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
.mytable-head {
    border: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-head td {
    border: 1px solid black;
}
.mytable-body {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-body td {
    border: 1px solid black;
    border-top: 0;
}
.mytable-footer {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
.mytable-footer td {
    border: 1px solid black;
    border-top: 0;
}
</style>